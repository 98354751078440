import {useDispatch, useSelector} from "react-redux";
import {useEffect, useMemo, useState} from "react";
import {fetchShops} from "@/redux/action-creaters/location";
import styles from './index.module.scss'
import clsx from "clsx";
import {showPopup} from "@/redux/action-creaters/popup";
import {popupTypes} from "@/redux/reducers/popupReducer";
import {getDeliveryList} from "@/redux/action-creaters/cart";
import {useRouter} from "next/router";
import Loader from "@/components/common/loader"
import { DeliveryTypes } from "~/types/cart";
import EdostCourier from "./edostCourier";
import Edost from "./edost";
import Sdek from "./sdek";
import Courier from "./courier";
import Shops from "./shops";
import {setDeliveryListAdditional} from "@/redux/action-creaters/cart";
import { Api } from "~/api";
import {getEdostMinPriceCorrected} from "@/utils";
import Button from "~/components/common/button";

export default function ProductDelivery({product, deliveryData, isLoadingDelivery}: any) {
    const {cityName} = useSelector((state: any) => state.location)
    const [currentId, setCurrentId] = useState(null)
    const [isLoadingShops, setLoadingShops] = useState(false)
    const dispatch = useDispatch();
    const router = useRouter();
    const {deliveyCityData} = useSelector((state: any) => state.location)
    const {deliveryList, deliveryListAdditional} = useSelector((state: any) => state.cart)

    const {contactsData} = useSelector((state: any) => state.contacts)

    const deliveryListFormatted = useMemo(() => {
        const deliveyCity = cityName
        let region = ''
        if (deliveyCityData) {
            region = deliveyCityData.region
        }
        //const host ='krasnodar.farkop.ru'.split('.')[0];
        let host = '';
        if (typeof window !== "undefined") {
            host = window.location.host.split('.')[0];
        }
        return deliveryList.map((item: any) => {
            const additionalItem = deliveryListAdditional?.find((additionalItem: any) => {
                return additionalItem.ID === item.ID
            })
            const discount = +item.DISCOUNT?.[host] || 0
            if (additionalItem) {
                return {
                    ...additionalItem,
                    ADDITIONAL: true,
                    PRICE:  additionalItem.PRICE ? +additionalItem.PRICE - discount : 0,
                    DISCOUNT: discount,
                    OLD_PRICE: +additionalItem.PRICE,
                };
            }
            return {
                ...item,
                PRICE:  +item.PRICE ? +item.PRICE - discount : 0,
                DISCOUNT: discount,
                OLD_PRICE: +item.PRICE,
            };
        }).filter((item: any) => {
            if (item.ADDITIONAL) {
                return true;
            }
            if (item.PRICE_RESTRICTIONS) {
                if (item.PRICE_RESTRICTIONS.MIN_PRICE && item.PRICE_RESTRICTIONS.MIN_PRICE > product.CATALOG.MIN_PRICE) {
                    return false;
                }
                if (item.PRICE_RESTRICTIONS.MAX_PRICE && item.PRICE_RESTRICTIONS.MAX_PRICE < product.CATALOG.MIN_PRICE) {
                    return false;
                }
            }
            if (deliveyCity === 'Санкт-Петербург') {
                if (item.LOCATION.includes('spb')) {
                    return true
                }
            } else if (region === "Ленинградская") {
                if (item.LOCATION.includes('spbObl')) {
                    return true
                }
            } else if (deliveyCity === 'Москва') {
                if (item.LOCATION.includes('msk')) {
                    return true
                }
            } else if (region === "Московская" || region === "Москва") {
                if (item.LOCATION.includes('mskObl')) {
                    return true
                }
            } else {
                if (item.LOCATION.includes('region')) {
                    return true
                }
            }
            return false
        });
    }, [deliveyCityData, deliveryList, deliveryListAdditional, cityName, product])

    useEffect(() => {
        if (cityName) {
            dispatch(setDeliveryListAdditional([]));
        }
    }, [cityName]);

    useEffect(() => {
        dispatch(getDeliveryList())
    }, []);

    const isLoading = useMemo(() => {
        return  isLoadingDelivery || isLoadingShops
    }, [isLoadingDelivery, isLoadingShops ])

    const currentDelivery = useMemo(() => {
        return deliveryListFormatted.find((item: any) => item.ID === currentId)
    }, [currentId, deliveryListFormatted]);

    const currentType = useMemo(() => {
        return currentDelivery?.TYPE
    }, [currentDelivery]);

    const onBuy = async () => {
        await Api.basket.addToBasket(product.ID)
        router.push('/personal/cart/')
    }

    useEffect(() => {
        let isSelectedItem = false;
        deliveryListFormatted.map((item: any) => {
            if (currentId === item.ID) {
                isSelectedItem = true;
            }
        })
        if (!isSelectedItem && deliveryListFormatted[0]?.ID) {
            setCurrentId(deliveryListFormatted[0]?.ID)
        }
    }, [deliveryListFormatted])

    const isSdekExist = useMemo(() => {
        return Boolean(deliveryListFormatted.find((item: any) => item.TYPE === DeliveryTypes.edost));
    }, [deliveryListFormatted]);

    return (
        <div className={styles.Delivery}>
            {cityName === 'Россия' && (
                <div>
                    Для расчета стоимости доставки выберите город:
                    <Button customStyles={styles.FormButton} label='Выбрать город' onClick={() => {
                        dispatch(showPopup(popupTypes.locationNew))
                    }}></Button>
                </div>
            )}
            {cityName !== 'Россия' && isLoading && (
                <div className={styles.Loader}>
                    <Loader/>
                </div>
            )}
            {cityName !== 'Россия' && !isLoading && (<>
                <div className={styles.Title}>Доставка в г. <span onClick={() => dispatch(showPopup(popupTypes.locationNew))}>{cityName}</span></div>

                 <div className={styles.Tabs} id="tabs">
                    {deliveryListFormatted.map((item: any) => (
                        <div key={item.ID} onClick={() => setCurrentId(item.ID)} className={clsx(styles.Item, currentId === item.ID && styles.Active)}>
                            <div>{item.NAME}</div>
                            <span>
                                {item.IS_PRICE_FROM && 'От '}
                                {item.PRICE > 0 && item.DISCOUNT > 0 && (
                                    <span className={styles.OldPrice}>{item.OLD_PRICE} руб</span>
                                )}
                                {item.PRICE > 0 ? (item.PRICE + ' руб') : item.DESCRIPTION}
                            </span>
                        </div>
                    ))}
                </div>
                <div className={styles.Content}>
                    <Shops
                        canBuy={product.CATALOG.AVAILABLE}
                        onBuy={onBuy}
                        currentType={currentType}
                        deliveryData={deliveryData}
                        product={product}
                        contactsData={contactsData}
                        isShow={currentType === DeliveryTypes.pickup || currentType === DeliveryTypes.install}
                    />
                    <Courier
                        canBuy={product.CATALOG.AVAILABLE}
                        onBuy={onBuy}
                        contactsData={contactsData}
                        isShow={currentType === DeliveryTypes.courier}
                    />
                    <Sdek
                        canBuy={product.CATALOG.AVAILABLE}
                        onBuy={onBuy}
                        isShow={currentType === DeliveryTypes.sdek}
                    />
                    {isSdekExist && <Edost
                        canBuy={product.CATALOG.AVAILABLE}
                        product={product}
                        onBuy={onBuy}
                        isShow={currentType === DeliveryTypes.edost}
                    />}
                    <EdostCourier
                        canBuy={product.CATALOG.AVAILABLE}
                        onBuy={onBuy}
                        price={currentDelivery?.DESCRIPTION}
                        isShow={currentType === DeliveryTypes.edostCourier}
                    />
                </div>
                </>
            )}
        </div>
    )
}