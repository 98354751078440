import {useSelector} from "react-redux";
import {useMemo} from "react"
import styles from './index.module.scss'

export default function Team({shopId, title = 'Команда'}) {
    const {team} = useSelector(state => state.content)

    const filteredTeam = useMemo(() => {
        if (shopId) {
            return team.filter(item => item.PROPERTY_SHOP_ID_VALUE === shopId)
        }
        return team
    }, [shopId]);

    const teamByDepartment = useMemo(() => {
        const departmets = {};
        filteredTeam.map(item => {
            if (!departmets[item.PROPERTY_DEPARTMENT_VALUE]) {
                departmets[item.PROPERTY_DEPARTMENT_VALUE] = [];
            }
            departmets[item.PROPERTY_DEPARTMENT_VALUE].push(item);
        })
        return departmets;
    }, [filteredTeam])

    return filteredTeam.length > 0 && (
        <div>
            {title && <h2>{title}</h2>}
            <div className={styles.Deps}>
                {Object.keys(teamByDepartment).map(dep => (
                    <div key={dep}>
                        {dep && dep !== 'null' && <h3>{dep}</h3>}
                        <div className={styles.Jobs}>
                            {teamByDepartment[dep].map(item => (
                                <div key={item.ID}>
                                    <img src={item.PICTURE_SRC}/>
                                    <div>{item.NAME}</div>
                                    <span>{item.PROPERTY_WORK_VALUE}</span>
                                </div>
                            ))}
                        </div>
                        <br/>
                        <br/>
                    </div>
                ))}
            </div>
        </div>
    )
}
